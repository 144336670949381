import {authSlice} from "../../modules/auth/state/auth_slice";
import {openStreetMapApi} from "../../modules/map/api/open_street_map_api";
import {notificationSlice} from "../../modules/notifications/state/notifications_slice";
import {recommendationDetailsModalSlice} from "../../modules/properties/api/recommendation_details_modal_state_slice";
import {recommendationsApi} from "../../modules/properties/api/recommendations_api";
import {recommendationsStateSlice} from "../../modules/properties/api/recommendations_state_slice";
import {userApi} from "../../modules/user/api/user_api";
import {variablesSlice} from "../state/variables_slice";

export const reducer = {
    [authSlice.name]: authSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [recommendationsApi.reducerPath]: recommendationsApi.reducer,
    [recommendationsStateSlice.name]: recommendationsStateSlice.reducer,
    [recommendationDetailsModalSlice.name]: recommendationDetailsModalSlice.reducer,
    [variablesSlice.name]: variablesSlice.reducer,
    [openStreetMapApi.reducerPath]: openStreetMapApi.reducer
};
