import {useRouteMatch} from "react-router";

import {mr, mt, onDesktop, pb, pointer} from "@pg-design/helpers-css";
import {LogoutIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {kmApiLink, kmAppLink, kmAppPath, SiteSlug} from "@pg-mono/km-routes";
import {appendQueryString} from "@pg-mono/request";

import {NavigationLinkType} from "../types/NavigationLinkType";
import {NavigationLink} from "./NavigationLink";

export const NavigationAccountSettings = () => {
    const match = useRouteMatch<{site: string}>(kmAppPath.site.base);
    const next = match?.params ? kmAppLink.site.baseWithParams({site: match.params.site as SiteSlug}) : undefined;
    const onLogout = () => (window.location.href = appendQueryString(kmApiLink.user.logout(), {next}));

    return (
        <div css={[pb(2), onDesktop(pb(0))]}>
            <NavigationLink
                id={NavigationLinkType.SETTINGS}
                label="Ustawienia konta"
                path={match?.params ? kmAppLink.site.user.baseWithParams({site: match.params.site as SiteSlug}) : ""}
            />
            <Text variant="body_copy_2" onClick={onLogout} css={[pointer, mt(1)]}>
                <LogoutIcon size="2" css={mr()} /> Wyloguj się
            </Text>
        </div>
    );
};
