import React, {Fragment, useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {ml, onDesktop} from "@pg-design/helpers-css";

import {Navigation} from "../../modules/navigation/components/Navigation";
import {setCanReadRecommendation} from "../../modules/properties/api/recommendations_state_slice";
import {useIntervalFetch} from "../../modules/properties/hooks/use_interval_fetch";
import {IRecommendationsCanReadState} from "../../modules/properties/types/IRecommendationsState";

interface IProps {
    children: React.ReactNode;
}

export const AuthenticatedLayout = (props: IProps) => {
    const dispatch = useDispatch();
    const [canReadRecommendationsState, setCanReadRecommendationsState] = useState<IRecommendationsCanReadState>({isReady: false, error: null});

    const changeState = (isReady: boolean, error: string | null) => {
        setCanReadRecommendationsState({isReady: isReady, error: error});
    };

    const {startIntervalFetch} = useIntervalFetch(changeState);

    useEffect(() => {
        if (!canReadRecommendationsState.isReady && !canReadRecommendationsState.error) {
            startIntervalFetch();
        }

        dispatch(setCanReadRecommendation({...canReadRecommendationsState}));
    }, [canReadRecommendationsState]);

    return (
        <Fragment>
            <Navigation />

            <div css={onDesktop(ml(25))}>{props.children}</div>
        </Fragment>
    );
};
