import {useCallback, useEffect, useRef} from "react";

import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";

import {fetchRecommendationsState} from "../api/fetch_recommendations_state";

export const useIntervalFetch = (changeState: (isReady: boolean, error: string | null) => void, interval = 750) => {
    const fetchInterval = useRef<number>();
    const isReady = useRef<boolean>(false);
    const error = useRef<string>();
    const limit = useRef<number>(0);

    useEffect(() => {
        return () => {
            if (fetchInterval.current) {
                window.clearTimeout(fetchInterval.current);
            }
        };
    }, []);

    const intervalFetch = async () => {
        if (isReady.current) {
            changeState(isReady.current, null);
            return;
        }

        if (limit.current > 20) {
            changeState(false, "API request limit exceeded");
            return;
        } else {
            limit.current++;
        }

        await fetchRecommendationsState()
            .then((response) => {
                if (response.data?.state === 2) {
                    isReady.current = true;
                }
            })
            .catch((err) => {
                error.current = err.message;
                notifyBugsnagClient(err, "fetchRecommendationsState error");
            });

        if (!!error.current) {
            changeState(false, error.current);
            return;
        }

        fetchInterval.current = window.setTimeout(intervalFetch, interval);
    };

    const handleIntervalFetch = useCallback(() => {
        if (isReady.current) {
            return;
        }

        intervalFetch();
    }, []);

    const startIntervalFetch = () => {
        handleIntervalFetch();
    };

    return {
        startIntervalFetch
    };
};
