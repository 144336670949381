import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {CheckboxCheckedIcon, CheckboxHalfCheckedIcon, CheckboxIcon} from "@pg-design/icons";

import {CheckboxSize, checkboxSizeValue} from "../utils/CheckboxSize";
import {ICheckboxProps} from "./Checkbox";

interface IProps extends Pick<ICheckboxProps, "readOnly" | "size" | "checked" | "checkedMarkColor"> {}

export const CheckboxDisplay = ({size = "md", ...props}: IProps) => {
    const {readOnly, checked, checkedMarkColor} = props;
    return (
        <div css={checkboxWrapStyle}>
            <CheckboxContainer size={size}>
                <CheckboxFrame readOnly={readOnly} checked={!!checked}>
                    <CheckboxIcon fill="transparent" />
                </CheckboxFrame>
                <CheckboxCheckedMark color={checkedMarkColor} readOnly={readOnly} checked={!!checked}>
                    {checked === "half-checked" ? <CheckboxHalfCheckedIcon fill="transparent" /> : <CheckboxCheckedIcon fill="transparent" />}
                </CheckboxCheckedMark>
            </CheckboxContainer>
        </div>
    );
};

const CheckboxContainer = styled.div<{size: CheckboxSize}>`
    width: ${(props) => checkboxSizeValue[props.size]};
    height: ${(props) => checkboxSizeValue[props.size]};
`;

const ComponentContainer = styled.div`
    display: inline-flex;
    align-items: center;
`;

const CheckboxFrame = styled.div<{readOnly?: boolean; checked: boolean}>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    svg {
        width: 100%;
        height: 100%;
        fill: ${({theme, readOnly}) => (readOnly ? theme.colors.gray["300"] : theme.colors.gray["700"])};
        transition: ${({theme}) => `fill, ${theme.transition.timingFunction}, ${theme.transition.duration}`};
    }

    ${({readOnly}) =>
        !readOnly &&
        css`
            ${ComponentContainer}:hover & {
                svg {
                    fill: black;
                }
            }
        `}
`;

const checkboxWrapStyle = css`
    border-radius: 0.4rem;
`;

const CheckboxCheckedMark = styled.div<{readOnly?: boolean; checked: boolean; color?: string}>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    opacity: ${({checked}) => (checked ? 1 : 0)};
    transition: ${({theme}) => `opacity, ${theme.transition.timingFunction}, ${theme.transition.duration}`};

    svg {
        width: 100%;
        height: 100%;
        transition: ${({theme}) => `fill, ${theme.transition.timingFunction}, ${theme.transition.duration}`};
        fill: ${({theme, readOnly, color}) => (readOnly ? theme.colors.gray["300"] : color || theme.colors.secondary)};
    }
`;
