import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IRecommendationsCanReadState} from "../types/IRecommendationsState";

export interface IRecommendationsStateStore {
    isReady: boolean;
    error: string | null;
}

const initialState: IRecommendationsStateStore = {
    isReady: false,
    error: null
};

export const recommendationsStateSlice = createSlice({
    name: "canReadRecommendationsState",
    initialState,
    reducers: {
        setCanReadRecommendation: (state, action: PayloadAction<IRecommendationsCanReadState>) => {
            return {...state, isReady: action.payload.isReady, error: action.payload.error};
        }
    }
});

export const {setCanReadRecommendation} = recommendationsStateSlice.actions;
