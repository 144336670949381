import React, {forwardRef, Ref} from "react";
import {GroupBase, OnChangeValue, StylesConfig} from "react-select";
import ReactSelectAsync, {AsyncProps as IReactSelectAsyncProps} from "react-select/async";
import Select from "react-select/base";
import {css, useTheme} from "@emotion/react";

import {ChevronDownIcon} from "@pg-design/icons";

import {useSelectTheme} from "../hooks/use_select_theme";
import {NoOptionsMessage} from "./NoOptionsMessage";

export interface IProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
    extends IReactSelectAsyncProps<Option, IsMulti, Group> {
    defaultOptions?: Option[] | boolean;
    isLoading?: boolean;
    loadOptions?: (inputValue: string, callback: (options: Option[]) => void) => Promise<Option[]>;
    defaultOptionMessage?: {title?: string; message?: string};
    disableDropdownIndicator?: boolean;
    onChange?: (value: OnChangeValue<Option, IsMulti>) => void;
    styles?: StylesConfig<Option, IsMulti, Group>;
}

export const SelectAsync = forwardRef(
    <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
        props: IProps<Option, IsMulti, Group>,
        forwardedRef: unknown
    ) => {
        const {disableDropdownIndicator, placeholder = "Wybierz", ...forwardProps} = props;
        const theme = useTheme();

        const {getTheme, styles} = useSelectTheme<Option, IsMulti, Group>();

        return (
            <ReactSelectAsync
                components={{
                    DropdownIndicator: (state) => {
                        return disableDropdownIndicator ? null : (
                            <ChevronDownIcon css={icon} size="2" fill={theme.colors.gray[state.isDisabled ? "300" : "700"]} />
                        );
                    }
                }}
                theme={getTheme}
                {...forwardProps}
                styles={{...styles, ...props.styles}}
                ref={forwardedRef as Ref<Select<Option, IsMulti, Group>>}
                placeholder={placeholder}
                noOptionsMessage={({inputValue}) => <NoOptionsMessage inputValue={inputValue} />}
            />
        );
    }
);

const icon = css`
    margin-right: 2rem;
`;
