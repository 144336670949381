// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Property} from "csstype";
import fp from "facepaint";

import {getBreakpoints} from "../utils/get_breakpoints";
import {getGridValuesFor} from "../utils/get_grid_values_for";

export type OneToFour = 1 | 2 | 3 | 4;
export type OneToTwelve = OneToFour | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type OneToSixteen = OneToTwelve | 13 | 14 | 15 | 16;

interface IProps {
    noGutters?: boolean;
    sm?: OneToFour;
    smOffset?: OneToFour;
    md?: OneToTwelve;
    mdOffset?: OneToTwelve;
    lg?: OneToSixteen;
    lgOffset?: OneToSixteen;
    justifyContent?: Property.JustifyContent;
}

const isInteger = (value: number) => isFinite(value) && Math.floor(value) === value;

const toPercent = (columns: number, size: number) => {
    const percentage = (size / columns) * 100;

    return isInteger(percentage) ? `${percentage}%` : `${percentage.toFixed(6)}%`;
};

const getPaddingValues = (values: number[], noGutters?: boolean) => {
    return values.map((value) => (noGutters ? 0 : value / 2));
};

// sm uses 1 to 4 grid, md uses 1 to 12, lg uses 1 to 16
export const Col = styled.div<IProps>`
    position: relative;
    width: 100%;

    ${(props) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}

    ${({noGutters, sm, smOffset, md, mdOffset, lg, lgOffset, theme: {grid}}) =>
        fp(getBreakpoints(grid))({
            marginLeft: [
                0,
                smOffset ? toPercent(grid.sm.columns, smOffset) : 0,
                mdOffset ? toPercent(grid.md.columns, mdOffset) : 0,
                lgOffset ? toPercent(grid.lg.columns, lgOffset) : 0
            ],
            paddingLeft: [8, ...getPaddingValues(getGridValuesFor("gutter", grid), noGutters)],
            paddingRight: [8, ...getPaddingValues(getGridValuesFor("gutter", grid), noGutters)],
            flex: [
                null,
                sm ? `1 0 ${toPercent(grid.sm.columns, sm)}` : "1 0 0",
                md && `1 0 ${toPercent(grid.md.columns, md)}`,
                lg && `1 0 ${toPercent(grid.lg.columns, lg)}`
            ],
            maxWidth: ["100%", sm ? toPercent(grid.sm.columns, sm) : "100%", md && toPercent(grid.md.columns, md), lg && toPercent(grid.lg.columns, lg)]
        })}
`;
