import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {ICountryCode} from "../types/ICountryCode";
import {IKeyVariable, IVariable} from "../types/IVariable";

export interface IFeedbackReason extends IKeyVariable {
    is_dislike_reason: boolean;
    is_dismiss_reason: boolean;
}

export interface IVariables {
    property_kitchen_type: IVariable[];
    recommendation_feedback_reason: IFeedbackReason[];
    recommendation_feedback_type: IVariable[];
    recommendation_status: IKeyVariable[];
    country_codes: ICountryCode[];
    offer_parking_place_type: IKeyVariable[];
    offer_standard_description_type: IKeyVariable[];
    offer_facilities: IVariable[];
    property_quarters: IKeyVariable[];
    recommendation_feature_feedback_feature: IKeyVariable[];
}

const initialState: IVariables = {
    property_kitchen_type: [],
    recommendation_feedback_reason: [],
    recommendation_feedback_type: [],
    recommendation_status: [],
    country_codes: [],
    offer_parking_place_type: [],
    offer_standard_description_type: [],
    offer_facilities: [],
    property_quarters: [],
    recommendation_feature_feedback_feature: []
};

export const variablesSlice = createSlice({
    name: "variables",
    initialState,
    reducers: {
        setVariables: (state, action: PayloadAction<IVariables>) => {
            return {
                ...state,
                ...action.payload
            };
        }
    }
});

export const {setVariables} = variablesSlice.actions;
