import React from "react";
import {css, SerializedStyles} from "@emotion/react";

import {calculateRemSize, position, zIndex} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {ModalCore, SystemModal} from "@pg-design/modal";

import {ModalConfirmLayout} from "./modal_layout/ModalConfirmLayout";
import {ModalConfirmLayoutCentered} from "./modal_layout/ModalConfirmLayoutCentered";
import {ModalEmptyLayout} from "./modal_layout/ModalEmptyLayout";
import {ModalPropertyLeadLayout} from "./modal_layout/ModalPropertyLeadLayout";
import {ModalSimpleSurveyLayout} from "./modal_layout/ModalSimpleSurveyLayout";

interface IProps {
    isOpen: boolean;
    showCloseButton?: boolean;
    onModalClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
    onAfterOpen?: () => void;
    onAfterClose?: () => void;
    children?: React.ReactNode;
    className?: string;
    closeButtonStyle?: SerializedStyles;
    variant?: "mobile_bottom_modal";
}

type ModalType = React.FC<IProps> & {
    Layout: {
        Confirm: typeof ModalConfirmLayout;
        ConfirmCentered: typeof ModalConfirmLayoutCentered;
        Empty: typeof ModalEmptyLayout;
        SimpleSurvey: typeof ModalSimpleSurveyLayout;
        PropertyLead: typeof ModalPropertyLeadLayout;
    };
};

export const Modal: ModalType = (props: IProps) => {
    if (props.variant === "mobile_bottom_modal") {
        return (
            <ModalCore showCloseButton={false} isOpen={props.isOpen} contentStyle={modalContent} overlayStyle={zIndex(100)} onModalClose={props.onModalClose}>
                <CloseIcon css={closeIcon} size="2" wrapperSize="3.2" wrapperColor="white" onClick={props.onModalClose} />

                {props.children}
            </ModalCore>
        );
    }

    return (
        <SystemModal
            onModalClose={props.onModalClose}
            isOpen={props.isOpen}
            showCloseButton={props.showCloseButton}
            onAfterOpen={props.onAfterOpen}
            onAfterClose={props.onAfterClose}
            className={props.className}
            closeButtonStyle={props.closeButtonStyle}
            zIndex={100}
        >
            {props.children}
        </SystemModal>
    );
};

Modal.Layout = {
    Confirm: ModalConfirmLayout,
    ConfirmCentered: ModalConfirmLayoutCentered,
    Empty: ModalEmptyLayout,
    SimpleSurvey: ModalSimpleSurveyLayout,
    PropertyLead: ModalPropertyLeadLayout
};

const modalContent = css`
    inset: auto ${calculateRemSize(1.5)} ${`-${calculateRemSize(1.5)}`} ${calculateRemSize(1.5)};
    overflow: visible;
`;

const closeIcon = css`
    ${position("absolute")};
    ${zIndex(100)};
    top: -28px;
    right: -28px;
`;
