import styled from "@emotion/styled";

interface IProps {
    on: "mobile" | "desktop";
}

export const Hidden = styled.div<IProps>`
    display: ${(props) => (props.on === "mobile" ? "none" : "block")};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        display: ${(props) => (props.on === "mobile" ? "block" : "none")};
    }
`;
