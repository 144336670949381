import {breakpoints} from "./base_breakpoints";

export const grid = {
    sm: {
        gutter: 16,
        columns: 4,
        offset: 12,
        value: 0,
        width: breakpoints.numeric.sm
    },
    md: {
        gutter: 24,
        columns: 12,
        offset: 20,
        value: breakpoints.numeric.md,
        width: 1192
    },
    lg: {
        gutter: 24,
        columns: 16,
        offset: 20,
        value: breakpoints.numeric.lg,
        width: 1576
    }
};
