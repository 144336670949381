import {Fragment} from "react";

import {mr} from "@pg-design/helpers-css";
import {CityIcon, EditIcon, FiltersIcon, ProgressIcon, SettingsIcon} from "@pg-design/icons";

import {NavigationLinkType} from "../types/NavigationLinkType";

interface IProps {
    type: NavigationLinkType;
}

export const LinkIcon = (props: IProps) => {
    switch (props.type) {
        case NavigationLinkType.MY_OFFERS:
            return <CityIcon size="2" css={mr()} />;
        case NavigationLinkType.PREFERENCES:
            return <FiltersIcon size="2" css={mr()} />;
        case NavigationLinkType.MARKET_DATA:
            return <ProgressIcon size="2" css={mr()} />;
        case NavigationLinkType.MY_ADS:
            return <EditIcon size="2" css={mr()} />;
        case NavigationLinkType.SETTINGS: {
            return <SettingsIcon size="2" css={mr()} />;
        }
        default:
            return <Fragment />;
    }
};
