import React, {ReactNode} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {m} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {useFieldStateColor} from "../hooks/use_field_state_color";
import {IFieldState} from "../types";

interface IProps {
    children: ReactNode;
    fieldState?: IFieldState;
    className?: string;
}
export function FieldMessage(props: IProps) {
    const {children, fieldState} = props;
    const theme = useTheme();
    const {getFieldStateColor} = useFieldStateColor();

    const fieldStateColor = getFieldStateColor(fieldState);

    let messageColor = fieldStateColor;
    if (!fieldState || fieldState === "disabled" || fieldState === "default") {
        messageColor = theme.colors.secondary;
    }

    return (
        <Message messageColor={messageColor} className={props.className}>
            <Text variant="info_txt_3" as="span">
                {children}
            </Text>
        </Message>
    );
}

const Message = styled.div<{messageColor: string}>`
    ${({messageColor}) => css`
        text-align: right;
        color: ${messageColor};
        ${m(0.5, 0, 0, 0)}
    `}
`;
