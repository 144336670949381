import {lighten} from "polished";

import {breakpoints, grid, IGridTheme} from "@pg-design/grid";
import {ITextTheme} from "@pg-design/text";

import {ICoreThemeDS} from "../interfaces/ICoreThemeDS";
import {colors} from "./colors";

export interface IThemeGH extends ICoreThemeDS, IGridTheme, ITextTheme {}

const primaryLighten = lighten(0.3, colors.primary);

// INFO: This is not a real GH theme - it should be updated to real values
export const themeGH: IThemeGH = {
    breakpoints,
    colors,
    globals: {
        body: {
            background: "#fff"
        },
        link: {
            color: colors.secondary,
            hover: {
                color: colors.highlight,
                decoration: "none"
            }
        },
        text: {
            color: colors.secondary,
            family: "Poppins, 'Helvetica Neue', Helvetica, Arial, sans-serif",
            lineHeight: "2rem",
            root: "10px",
            size: {
                desktop: "1.4rem",
                mobile: "1.2rem"
            },
            weight: 400
        },
        checkbox: {
            checkedColor: "#02d054"
        }
    },
    grid,
    transition: {
        timingFunction: "cubic-bezier(1, 0, 0, 1)",
        duration: "0.4s"
    },
    text: {
        family: {
            primary: "'Poppins', sans-serif",
            secondary: "'Playfair_Display', serif"
        }
    },
    button: {
        primaryLabelColor: "#fff",
        highlightColor: primaryLighten
    }
};
