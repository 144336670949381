import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router";
import {css} from "@emotion/react";

import {Container} from "@pg-design/grid";
import {minHeight, onDesktop} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {useModal} from "@pg-design/modal";
import {Text} from "@pg-design/text";
import {kmAppLink, SiteSlug} from "@pg-mono/km-routes";

import {CenterBox} from "../../../components/CenterBox";
import {Modal} from "../../feedback/components/generic_modal/Modal";
import {addNotification} from "../../notifications/state/notifications_slice";
import {cplUnsubscribeUser} from "../api/cpl_unsubscribe_user";
import {ActivitySource} from "../constants/ActivitySource";

export const CplUnsubscribeView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<{uuid: string; site: keyof typeof ActivitySource}>();

    const {shouldModalBeOpened: showModal, closeModal} = useModal({openedByDefault: true});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // Only `rynekpierwotny` site param should be handled
        const allowedSite = params.site && params.site === SiteSlug.RP;

        allowedSite ? setLoading(false) : pushToHomePage();
    }, []);

    const onUnsubscribe = () => {
        cplUnsubscribeUser(params.uuid)
            .then(() => closeModal())
            .catch(() => dispatch(addNotification({type: "failure", content: "Coś poszło nie tak. Spróbuj później."})));
    };

    const pushToHomePage = () => {
        history.push(kmAppLink.site.baseWithParams({site: SiteSlug.KM}));
    };

    const loader = (
        <CenterBox css={minHeight("100vh")}>
            <Loader size="lg" />
        </CenterBox>
    );

    if (loading) {
        return loader;
    }

    return (
        <div>
            <Container>
                {showModal ? (
                    loader
                ) : (
                    <CenterBox css={minHeight("100vh")}>
                        <Text variant="headline_0" mb="1.6rem">
                            Przykro nam, że nas opuszczasz
                        </Text>
                        <Text variant="body_copy_0">Szanujemy Twoje zdanie - nie będziemy już wysyłać rekomendacji na Twój na adres e-mail.</Text>
                    </CenterBox>
                )}
            </Container>

            <Modal isOpen={showModal} onModalClose={pushToHomePage} css={modalStyle}>
                <Modal.Layout.ConfirmCentered
                    title="Czy na pewno chcesz usunąć swój adres e‑mail z naszej bazy rekomendacji?"
                    onConfirmation={onUnsubscribe}
                    onCancel={pushToHomePage}
                    okButtonText="Tak"
                    cancelButtonText="Nie"
                />
            </Modal>
        </div>
    );
};

const modalStyle = css`
    ${onDesktop(css`
        max-width: 66rem;
    `)};
`;
