import {css} from "@emotion/react";

import {fontFace, globals, normalize, resetStyles} from "@pg-design/styles";

import {ITheme} from "../../hooks/use_matched_theme";
import {STATIC_PATH} from "../constants/paths";

const fontVariant = "poppins";
const fontPath = `${STATIC_PATH}/fonts/${fontVariant}/`;

export const getGlobalStyles = (theme: ITheme) => css`
    ${resetStyles}

    ${fontFace(fontPath, fontVariant, `${fontVariant}_light`, 300)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_regular`, 400)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_medium`, 500)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_semibold`, 600)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_bold`, 700)};

    ${normalize};
    ${globals(theme)};

    a:hover,
    a:focus {
        outline: 0;
    }

    html,
    body,
    #root {
        min-height: 100vh;
    }

    .ReactModal__Body--open {
        overflow: hidden;
        padding-right: 15px;
    }
`;
