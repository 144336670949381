import React from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    inputValue?: string;
}

export const NoOptionsMessage = (props: IProps) => {
    return (
        <div css={noOptionsMessage}>
            {props.inputValue ? (
                <>
                    <Text mb="0.8rem" variant="body_copy_1">
                        Niestety nic nie znaleźliśmy
                    </Text>
                    <Text variant="info_txt_2">Zmień parametry wyszukiwania</Text>
                </>
            ) : (
                <Text variant="body_copy_1">Wpisz wartość...</Text>
            )}
        </div>
    );
};

const noOptionsMessage = (theme: Theme) => css`
    ${p(2)};
    ${borderRadius()};
    background-color: ${theme.colors.gray["200"]};
`;
