import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {IFit} from "./Picture";

interface IImage {
    alt: string;
    height: number;
    width: number;
    ref: React.RefObject<HTMLImageElement>;
    src?: string;
    srcSet?: string;
    loading?: "lazy";
    fit?: IFit;
    hasError?: boolean;
}

export const Image = styled.img<IImage>`
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;

    ${(props) => css`
        ${props.hasError ? `display: none;` : ""}
    `}

    ${(props) =>
        props.fit &&
        css`
            object-fit: cover;
            object-position: ${props.fit};
            height: 100%;
            width: 100%;
        `}
`;
