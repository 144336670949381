import React, {useRef, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Property} from "csstype";

import {Placeholder} from "@pg-design/image";

import {Image} from "./Image";

interface IPictureSourceBase {
    minWidthPX: number;
    src?: string | undefined;
    width: number;
    height: number;
}

export interface IPictureSource extends IPictureSourceBase {
    src?: string | undefined;
}

export interface IRetinaPictureSource extends IPictureSourceBase {
    // If image is a set of sources (in our case separate source for retina display) then we need to use `srcSet` attribute on img tag
    srcSet: `${string} 1x, ${string} 2x`;
}

export interface IPictureProps {
    sources: (IRetinaPictureSource | IPictureSource)[];
    alt: string;
    loading?: "lazy";
    className?: string;
    fit?: IFit;
}

export type IFit = Property.ObjectPosition | `${Property.ObjectPosition} ${Property.ObjectPosition}`;

export const Picture = (props: IPictureProps) => {
    const [hasError, setHasError] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);

    const onError = () => setHasError(true); // onError does not always trigger on page load, but is working correctly on live SPA behaviour
    const {sources, className, fit} = props;
    const mediaUrls = sources.sort((a, b) => b.minWidthPX - a.minWidthPX);
    const smallestImage = mediaUrls[mediaUrls.length - 1];

    return (
        <PictureBase className={className} onError={onError} hasError={hasError} containerSizes={sources} fit={fit}>
            <>
                {(hasError || props.sources.length === 0) && <Placeholder />}

                {mediaUrls.map(({minWidthPX, width, height, ...restMedia}) => {
                    const src = "srcSet" in restMedia ? restMedia.srcSet : restMedia.src;

                    return <source key={minWidthPX} media={`(min-width: ${minWidthPX}px)`} width={width} height={height} srcSet={src} />;
                })}

                <Image
                    alt={props.alt}
                    loading={props.loading}
                    height={smallestImage.height}
                    width={smallestImage.width}
                    fit={fit}
                    ref={imgRef}
                    hasError={hasError}
                    onLoad={(img) => {
                        /**
                         * fix: we did get some image urls that returned 403 from amazon.
                         * onError` callback should set the state to show placeholder, but it does not always trigger right after a page load.
                         * Change on error is forced here.
                         */
                        if (img.currentTarget.complete && img.currentTarget.naturalWidth === 0) {
                            setHasError(true);
                        }

                        if (img.currentTarget.complete && img.currentTarget.naturalWidth !== 0) {
                            setHasError(false);
                        }
                    }}
                    {...getSrcProp(smallestImage)}
                />
            </>
        </PictureBase>
    );
};

const getSrcProp = (media: IPictureSource | IRetinaPictureSource) => ("srcSet" in media ? {srcSet: media.srcSet} : {src: media.src});

const PictureBase = styled.picture<{hasError: boolean; containerSizes: {minWidthPX: number; height: number; width: number}[]; fit?: IFit}>`
    display: block;
    ${(props) =>
        props.fit &&
        css`
            height: 100%;
        `}

    ${(props) =>
        props.hasError &&
        css`
            position: relative;
            width: 100%;

            &:before {
                content: "";
                display: block;
            }

            ${props.containerSizes
                .sort((a, b) => a.minWidthPX - b.minWidthPX)
                .map(
                    (size) => css`
                        @media screen and (min-width: ${size.minWidthPX + "px"}) {
                            max-width: ${size.width + "px"};

                            &:before {
                                ${getAspectRatioPadding(size.height, size.width)}
                            }
                        }
                    `
                )}
        `}
`;

const getAspectRatioPadding = (height: number, width: number) => css`
    padding-top: ${(height / width) * 100 + "%"};
`;
