import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {CloseIcon} from "@pg-design/icons";
import {Text, TextVariant} from "@pg-design/text";
import {useFancyUnmount, useIsMounted} from "@pg-mono/hooks";

import {INotificationType} from "../types";

export interface INotificationProps {
    notificationData: INotification;
    removeNotificationFromStore: () => void;
}

export interface INotification {
    id: string;
    type: INotificationType;
    content: JSX.Element | string;
    timeout: number;
}

const animationDuration = 2000;

export const Notification = (props: INotificationProps) => {
    const {
        notificationData: {timeout, type, content},
        removeNotificationFromStore
    } = props;

    const isMounted = useIsMounted();

    const {unmount, runUnmountAnimation} = useFancyUnmount(
        animationDuration,
        () => {
            removeNotificationFromStore();
        },
        timeout
    );

    return (
        <NotificationWrap runUnmountAnimation={runUnmountAnimation} animationDuration={animationDuration} isMounted={isMounted}>
            <StyledNotification type={type}>
                <div css={contentWrap}>
                    {/*<span dangerouslySetInnerHTML={{__html: content}} />*/}
                    <Text as="span" variant={TextVariant.INFO_TXT_1}>
                        {content}
                    </Text>
                </div>
                <div css={closeButtonWrap}>
                    <StyledCloseButton
                        type={type}
                        onClick={() => {
                            unmount();
                        }}
                    >
                        <CloseIcon />
                    </StyledCloseButton>
                </div>
            </StyledNotification>
        </NotificationWrap>
    );
};

const NotificationWrap = styled.li<{runUnmountAnimation: boolean; animationDuration: number; isMounted: boolean}>`
    position: relative;
    display: flex;
    
    ${({theme}) => css`
        transition: opacity, ${theme.transition.timingFunction}, ${animationDuration / 1000}s;
    `}
    
    ${({isMounted}) =>
        isMounted
            ? css`
                  opacity: 1;
              `
            : css`
                  opacity: 0;
              `};

    ${({runUnmountAnimation}) =>
        runUnmountAnimation
            ? css`
                  transform: translateX(100%);
                  opacity: 0;
              `
            : css``}
    }
`;

export const StyledNotification = styled.div<{type: INotificationType}>`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    min-height: 8.6rem;
    margin-bottom: 2rem;
    border-radius: 0.8rem;

    color: ${({type, theme}) => (type === "failure" ? "#fff" : theme.colors.secondary)};

    background: ${({type, theme}) => {
        switch (type) {
            case "action":
                return theme.colors.primary;
            case "success":
                return theme.colors.success;
            case "info":
                return theme.colors.info;
            case "warning":
                return theme.colors.warning;
            case "failure":
                return theme.colors.secondary;
            default:
                return theme.colors.primary;
        }
    }};
`;
const contentWrap = css`
    padding: 1.8rem 3.8rem 1.8rem 1.8rem;
    display: flex;
    align-items: center;
`;
const closeButtonWrap = css`
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    display: flex;
    align-items: flex-start;
    padding: 0.8rem;
`;
const StyledCloseButton = styled.div<{type: INotificationType}>`
    border: 0;
    outline: 0;
    cursor: pointer;

    svg {
        width: 1.6rem;
        height: 1.6rem;
        fill: ${({theme, type}) => (type === "failure" ? "#fff" : theme.colors.secondary)};
    }
`;
