import React, {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, calculateRemSize, flex, flexDirection, flexJustifyCenter, mb, mt, onDesktop, p, pr} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";

import {PropertyMainInfoTexts} from "../../../../properties/components/PropertyMainInfoTexts";
import {IPropertyDetail} from "../../../../properties/types/IPropertyDetail";

interface IProps {
    property: IPropertyDetail;
    children?: ReactNode;
}

export const ModalPropertyLeadLayout = (props: IProps) => {
    const {property, children} = props;
    const img = property.images.find((el) => el.thumbnails.g_img_155x87)?.thumbnails.g_img_155x87;

    return (
        <div css={innerWrap}>
            <div css={propertyWrapperStyle}>
                <div css={propertyCoverStyle}>
                    <Picture
                        css={[flexJustifyCenter, onDesktop(mb(3))]}
                        sources={[{height: 80, width: 160, minWidthPX: 0, src: img}]}
                        alt={`${property.offer.name} - ${property.flat_number || property.building_number}`}
                    />
                </div>
                <div css={propertyInfoStyle}>
                    <PropertyMainInfoTexts property={property} />
                </div>
            </div>

            <div css={[mt(4)]}>{children}</div>
        </div>
    );
};

const innerWrap = css`
    position: relative;
    max-height: 100vh;
    height: 100%;
    max-width: 100vw;
    overflow: auto;
    display: flex;
    flex-direction: column;
    ${p(3, 1.5)};

    ${onDesktop(css`
        height: auto;
        ${p(3)};
        ${borderRadius()};
        width: 55rem;
    `)};
`;

const propertyWrapperStyle = (theme: Theme) => css`
    ${flex()};
    ${flexDirection("column")};
    gap: ${calculateRemSize(3)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${flexDirection("row")};
    }
`;

const propertyCoverStyle = css`
    width: 160px;
    height: 80px;

    ${onDesktop(css`
        min-width: 160px;
    `)};
`;

const propertyInfoStyle = css`
    ${pr(7)};

    ${onDesktop(css`
        ${pr(4)};
    `)};
`;
