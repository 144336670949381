// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styled from "@emotion/styled";
import fp from "facepaint";

import {getBreakpoints} from "../utils/get_breakpoints";
import {getGridValuesFor} from "../utils/get_grid_values_for";

const getMarginValues = (values: number[]) => {
    return values.map((value) => `0 -${value / 2}px`);
};

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;

    ${({theme: {grid}}) =>
        fp(getBreakpoints(grid))({
            margin: ["0 0 0 8px", ...getMarginValues(getGridValuesFor("gutter", grid))]
        })}
`;
