import React from "react";
import {GroupBase} from "react-select";

import {ISelectProps, Select} from "@pg-design/select";

import {FieldWrapper, IFieldWrapperProps} from "../atoms/wrapper/FieldWrapper";
import {getFieldState} from "../field_state_helper";
import {getErrorMessage} from "../utils/get_error_message";

export type ISelectFieldProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = IFieldWrapperProps &
    ISelectProps<Option, IsMulti, Group> & {
        error?: string | string[];
    };

export const SelectField = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: ISelectFieldProps<Option, IsMulti, Group>
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {labelContent, message, id, className, error, ...selectProps} = props;

    const fieldState = getFieldState(props);
    const errorMessage = getErrorMessage(props.error);

    return (
        <FieldWrapper labelContent={labelContent} fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <Select {...selectProps} />
        </FieldWrapper>
    );
};
