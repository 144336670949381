import {useHistory, useLocation} from "react-router";
import {css} from "@emotion/react";

import {flex, flexDirection, mb, mt, pointer, position, pv, textAlign} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {useReadRecommendationsStateQuery} from "../../properties/api/recommendations_api";
import {LinkIcon} from "../atoms/LinkIcon";
import {LinkWithCount} from "../atoms/LinkWithCount";
import {INavigationLinks} from "../types/INavigationLinks";
import {NavigationLinkType} from "../types/NavigationLinkType";

export const NavigationLink = (props: INavigationLinks) => {
    const {id, path, label, subLinks} = props;
    const {data} = useReadRecommendationsStateQuery();
    const history = useHistory();
    const location = useLocation();

    const linksWithCount = [
        NavigationLinkType.MY_OFFERS,
        NavigationLinkType.FAVORITE,
        NavigationLinkType.DISMISSED,
        NavigationLinkType.APPLICATION_SENT,
        NavigationLinkType.RECOMMENDED
    ];

    const checkIsActive = (path: string) => {
        if (id === NavigationLinkType.SETTINGS) {
            return location.pathname.includes("konto/");
        }
        return path === location.pathname;
    };

    const redirect = (path: string) => {
        if (path) {
            window.scrollTo({top: 0});
            history.push(path);
        }
    };

    return (
        <div css={linkWrapper}>
            <Text variant="body_copy_2" onClick={() => redirect(path)} css={[pointer, pv(2)]} strong={checkIsActive(path)}>
                {linksWithCount.includes(id) && data?.stats ? (
                    <LinkWithCount type={id} label={label} stats={data.stats} />
                ) : (
                    <>
                        <LinkIcon type={id} />
                        {label}
                    </>
                )}
            </Text>
            {subLinks && (
                <div css={[flex("normal", "center"), flexDirection("column"), pv(2), textAlign("center")]}>
                    {subLinks.map((link, index) => {
                        return (
                            <Text key={index} variant="info_txt_1" onClick={() => redirect(link.path)} css={subLinkStyle} strong={checkIsActive(link.path)}>
                                {data?.stats ? (
                                    <LinkWithCount type={link.id} label={link.label} stats={data.stats} />
                                ) : (
                                    <>
                                        <LinkIcon type={link.id} />
                                        {link.label}
                                    </>
                                )}
                            </Text>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const linkWrapper = css`
    border-bottom: 1px solid #f9f9f9;
`;

const subLinkStyle = css`
    ${pointer};
    ${mb(3)};
    ${position("relative")};

    &:last-child {
        ${mt(3)};
        ${mb(0)};
    }
`;
