import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {kmApiLink} from "@pg-mono/km-routes";

import {getFetchHeaders} from "../../../app/utils/get_fetch_headers";
import {IUserFeedback} from "../../feedback/types/IUserFeedback";
import {PAGE_SIZE} from "../constants/pagination_constants";
import {IApplicationPayload} from "../types/IApplicationPayload";
import {IApplicationResponse} from "../types/IApplicationResponse";
import {IFeatureFeedback, IFeatureFeedbackData} from "../types/IFeatureFeedback";
import {IQuery} from "../types/IQuery";
import {IRecommendations} from "../types/IRecommendations";
import {IRecommendationsState} from "../types/IRecommendationsState";

export const recommendationsApi = createApi({
    reducerPath: "recommendations",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        headers: getFetchHeaders()
    }),
    tagTypes: ["List", "State", "FeatureFeedback"],
    endpoints: (builder) => ({
        readRecommendationsList: builder.query<IRecommendations, IQuery>({
            query: ({sort, status, offset, isSold}) => {
                const params: Record<string, string | number | boolean> = {};
                if (status) {
                    params.status = status;
                }

                if (offset) {
                    params.offset = offset;
                }

                if (sort) {
                    params.ordering = sort;
                }

                if (typeof isSold === "boolean") {
                    params.is_sold = isSold;
                }

                return {
                    url: kmApiLink.recommendations.recommendation(),
                    params: {limit: PAGE_SIZE, ...params}
                };
            },
            providesTags: ["List"]
        }),
        readRecommendationsState: builder.query<IRecommendationsState, void>({
            query: () => {
                return {
                    url: kmApiLink.recommendations.state()
                };
            },
            providesTags: ["State"]
        }),
        updateRecommendationViewed: builder.mutation<IRecommendations, {recommendation: number}>({
            query: (request) => {
                return {
                    url: kmApiLink.recommendations.markAsSeen(),
                    method: "POST",
                    body: request,
                    headers: getFetchHeaders()
                };
            },
            invalidatesTags: ["List", "State"]
        }),
        updateRecommendationUserFeedback: builder.mutation<IRecommendations, IUserFeedback>({
            query: (request) => {
                return {
                    url: kmApiLink.recommendations.feedback(),
                    method: "POST",
                    body: request,
                    headers: getFetchHeaders()
                };
            },
            invalidatesTags: ["List", "State"]
        }),
        markRecommendationFavourite: builder.mutation<{recommendation: number}, {recommendation: number}>({
            query: (request) => {
                return {
                    url: kmApiLink.recommendations.markAsFavourite(),
                    method: "POST",
                    body: request,
                    headers: getFetchHeaders()
                };
            },
            invalidatesTags: ["List", "State"]
        }),
        markRecommendationDismissed: builder.mutation<{recommendation: number}, {recommendation: number}>({
            query: (request) => {
                return {
                    url: kmApiLink.recommendations.markAsDismissed(),
                    method: "POST",
                    body: request,
                    headers: getFetchHeaders()
                };
            },
            invalidatesTags: ["List", "State"]
        }),
        restoreRecommendation: builder.mutation<{recommendation: number}, {recommendation: number}>({
            query: (request) => {
                return {
                    url: kmApiLink.recommendations.restore(),
                    method: "POST",
                    body: request,
                    headers: getFetchHeaders()
                };
            },
            invalidatesTags: ["List", "State"]
        }),
        sendApplication: builder.mutation<IApplicationResponse, IApplicationPayload>({
            query: (payload) => {
                return {
                    url: kmApiLink.applications.application(),
                    method: "POST",
                    body: payload,
                    headers: getFetchHeaders()
                };
            }
        }),
        getFeatureFeedback: builder.query<IFeatureFeedbackData, {recommendationId: number}>({
            query: (payload) => {
                return {
                    url: kmApiLink.recommendations.featureFeedback(),
                    params: {recommendation: payload.recommendationId}
                };
            },
            providesTags: ["FeatureFeedback"]
        }),
        sendFeatureFeedback: builder.mutation<unknown, IFeatureFeedback>({
            query: (payload) => {
                return {
                    url: kmApiLink.recommendations.sendFeatureFeedback(),
                    method: "POST",
                    headers: getFetchHeaders(),
                    body: {recommendation: payload.recommendationId, type: payload.type, feature: payload.feature}
                };
            },
            invalidatesTags: ["FeatureFeedback"]
        })
    })
});

export const {
    useReadRecommendationsListQuery,
    useReadRecommendationsStateQuery,
    useUpdateRecommendationViewedMutation,
    useUpdateRecommendationUserFeedbackMutation,
    useMarkRecommendationDismissedMutation,
    useMarkRecommendationFavouriteMutation,
    useRestoreRecommendationMutation,
    useSendApplicationMutation,
    useGetFeatureFeedbackQuery,
    useSendFeatureFeedbackMutation
} = recommendationsApi;
