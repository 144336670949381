import styled from "@emotion/styled";

interface IHighlightProps {
    strong?: boolean;
    href?: string;
    color?: string;
}

export const Highlight = styled.span<IHighlightProps>`
    overflow: hidden;
    background-image: linear-gradient(${(props) => (props.color ? props.color : props.theme.colors.primary)}, ${(props) =>
        props.color ? props.color : props.theme.colors.primary} 30%, transparent 30%);
    background-repeat: no-repeat;
    background-position: 0 0.8em;
    position: relative;
    font-weight: ${(props) => props.strong && "700"};
    
    animation: underline 1.5s linear;
    @keyframes underline {
        from {
            background-position: -100em 0.8em;
        }
        to {
            background-position: 0 0.8em;
        }
`;
