import React, {FC} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {TextVariant, TextVariantUnionType} from "../utils/text_variant";
import {generateTypeScale, majorSecondScaleFN} from "../utils/type_scale";

const defaultVariantMapping: Record<TextVariantUnionType, React.ElementType> = {
    [TextVariant.HEADLINE_0]: "h1",
    [TextVariant.HEADLINE_1]: "h1",
    [TextVariant.HEADLINE_2]: "h2",
    [TextVariant.HEADLINE_3]: "h3",
    [TextVariant.HEADLINE_4]: "h4",
    [TextVariant.HEADLINE_5]: "h5",
    [TextVariant.HEADLINE_6]: "h6",
    [TextVariant.BODY_COPY_0]: "p",
    [TextVariant.BODY_COPY_1]: "p",
    [TextVariant.BODY_COPY_2]: "p",
    [TextVariant.INFO_TXT_1]: "p",
    [TextVariant.INFO_TXT_2]: "p",
    [TextVariant.INFO_TXT_3]: "p",
    [TextVariant.BUTTON_BIG]: "span",
    [TextVariant.BUTTON_SMALL]: "span",
    [TextVariant.BUTTON_MEDIUM]: "span",
    [TextVariant.BUTTON_MICRO]: "span",
    [TextVariant.MINI_HEADER]: "span"
};

type ITextMargin = "0.4rem" | "0.8rem" | "1.2rem" | "1.6rem" | "2.4rem" | "2.8rem" | "3.2rem" | "4.8rem" | "6.4rem";

export interface ITextProps {
    children: React.ReactNode;
    as?: React.ElementType;
    variant?: TextVariantUnionType;
    align?: "start" | "center" | "inherit" | "justify" | "left" | "right";
    noWrap?: boolean;
    strong?: boolean;
    mb?: ITextMargin;
    fontFamily?: "primary" | "secondary";
    className?: string;
    id?: string;
    onClick?: (e: React.MouseEvent) => void;
    color?: string;
    testId?: string;
}

export const Text: FC<ITextProps> = (props) => {
    const {as, variant = TextVariant.BODY_COPY_0, noWrap = false, align = "start", strong = false, fontFamily = "primary", mb, color} = props;
    const Component = as || (variant && defaultVariantMapping[variant]) || "span";

    const variantStyle = getTextVariantStyleFor(variant, strong);

    return (
        <TextBase
            className={props.className}
            as={Component}
            css={variantStyle}
            fontFamily={fontFamily}
            noWrap={noWrap}
            align={align}
            mb={mb}
            onClick={props.onClick}
            id={props.id}
            color={color}
            data-testid={props.testId}
        >
            {props.children}
        </TextBase>
    );
};

interface ITextBase {
    align?: "start" | "center" | "inherit" | "justify" | "left" | "right";
    noWrap?: boolean;
    mb?: string;
    fontFamily?: "primary" | "secondary";
    color?: string;
}

export const TextBase = styled.span<ITextBase>`
    margin: 0;
    font-family: ${(props) =>
        props.fontFamily === "primary"
            ? props.theme?.text?.family?.primary ?? "'Poppins', sans-serif"
            : props.theme?.text?.family?.secondary ?? "'Playfair_Display', serif"};

    // Prop styles
    ${(props) =>
        props.noWrap &&
        css`
            white-space: nowrap;
        `}

    ${(props) =>
        props.align &&
        css`
            text-align: ${props.align};
        `}
    
    ${(props) =>
        props.mb &&
        css`
            margin-bottom: ${props.mb};
        `}
    
    ${(props) =>
        props.color &&
        css`
            color: ${props.color};
        `}
`;

const desktopTypeScale = generateTypeScale(majorSecondScaleFN(1.4));
const mobileTypeScale = generateTypeScale(majorSecondScaleFN(1.2));

const lineHeight = {
    1200: "5.2rem",
    1100: "4.8rem",
    1000: "4.4rem",
    900: "4rem",
    800: "3.6rem",
    700: "3.2rem",
    600: "2.8rem",
    500: "2.4rem",
    400: "2rem",
    300: "1.6rem",
    200: "1.2rem",
    100: "1rem"
};

export const getTextVariantStyleFor = (variant: TextVariantUnionType, strong?: boolean) => (theme: Theme) => {
    switch (variant) {
        case TextVariant.HEADLINE_0: {
            return css`
                font-weight: ${strong ? 700 : 600};
                font-size: ${mobileTypeScale[1200]};
                line-height: ${lineHeight[900]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[1200]};
                    line-height: ${lineHeight[1200]};
                }
            `;
        }

        case TextVariant.HEADLINE_1: {
            return css`
                font-weight: 700;
                font-size: ${mobileTypeScale[1100]};
                line-height: ${lineHeight[800]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[1100]};
                    line-height: ${lineHeight[1000]};
                }
            `;
        }

        case TextVariant.HEADLINE_2: {
            return css`
                font-weight: 700;
                font-size: ${mobileTypeScale[1000]};
                line-height: ${lineHeight[700]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[1000]};
                    line-height: ${lineHeight[800]};
                }
            `;
        }

        case TextVariant.HEADLINE_3: {
            return css`
                font-weight: ${strong ? 700 : 500};
                font-size: ${mobileTypeScale[900]};
                line-height: ${lineHeight[600]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[900]};
                    line-height: ${lineHeight[700]};
                }
            `;
        }

        case TextVariant.HEADLINE_4: {
            return css`
                font-weight: ${strong ? 700 : 500};
                font-size: ${mobileTypeScale[800]};
                line-height: ${lineHeight[500]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[800]};
                    line-height: ${lineHeight[600]};
                }
            `;
        }
        case TextVariant.HEADLINE_5: {
            return css`
                font-weight: ${strong ? 700 : 500};
                font-size: ${mobileTypeScale[700]};
                line-height: ${lineHeight[500]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[700]};
                    line-height: ${lineHeight[500]};
                }
            `;
        }
        case TextVariant.HEADLINE_6: {
            return css`
                font-weight: ${strong ? 700 : 500};
                font-size: ${mobileTypeScale[600]};
                line-height: ${lineHeight[400]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[600]};
                    line-height: ${lineHeight[500]};
                }
            `;
        }
        case TextVariant.BODY_COPY_0: {
            return css`
                font-weight: ${strong ? 700 : 400};
                font-size: ${mobileTypeScale[600]};
                line-height: ${lineHeight[500]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[600]};
                    line-height: ${lineHeight[600]};
                }
            `;
        }
        case TextVariant.BODY_COPY_1: {
            return css`
                font-weight: ${strong ? 700 : 400};
                font-size: ${mobileTypeScale[500]};
                line-height: ${lineHeight[400]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[500]};
                    line-height: ${lineHeight[500]};
                }
            `;
        }
        case TextVariant.BODY_COPY_2: {
            return css`
                font-weight: ${strong ? 700 : 400};
                font-size: ${mobileTypeScale[400]};
                line-height: ${lineHeight[400]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[400]};
                    line-height: ${lineHeight[400]};
                }
            `;
        }
        case TextVariant.INFO_TXT_1: {
            return css`
                font-weight: ${strong ? 700 : 400};
                font-size: ${mobileTypeScale[300]};
                line-height: ${lineHeight[200]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[300]};
                    line-height: ${lineHeight[300]};
                }
            `;
        }
        case TextVariant.INFO_TXT_2: {
            return css`
                font-weight: ${strong ? 700 : 400};
                font-size: ${mobileTypeScale[200]};
                line-height: ${lineHeight[200]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[200]};
                    line-height: ${lineHeight[200]};
                }
            `;
        }
        case TextVariant.INFO_TXT_3: {
            return css`
                font-weight: ${strong ? 700 : 400};
                font-size: ${mobileTypeScale[100]};
                line-height: ${lineHeight[200]};

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[100]};
                    line-height: ${lineHeight[200]};
                }
            `;
        }
        case TextVariant.BUTTON_BIG: {
            return css`
                font-weight: 600;
                font-size: ${desktopTypeScale[600]}; // UX: Button is a exception from typescale rule. It has same size on mobile and desktop
                line-height: ${lineHeight[500]};

                text-transform: uppercase;
                letter-spacing: 0.02em;

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[600]};
                    line-height: ${lineHeight[500]};
                }
            `;
        }
        case TextVariant.BUTTON_MEDIUM: {
            return css`
                font-weight: 600;
                font-size: ${desktopTypeScale[500]}; // UX: Button is a exception from typescale rule. It has same size on mobile and desktop
                line-height: ${lineHeight[500]};

                text-transform: uppercase;
                letter-spacing: 0.02em;

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[500]};
                    line-height: ${lineHeight[500]};
                }
            `;
        }
        case TextVariant.BUTTON_SMALL: {
            return css`
                font-weight: 600;
                font-size: ${desktopTypeScale[400]}; // UX: Button is a exception from typescale rule. It has same size on mobile and desktop
                line-height: ${lineHeight[400]};

                text-transform: uppercase;
                letter-spacing: 0.02em;

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[400]};
                    line-height: ${lineHeight[400]};
                }
            `;
        }

        case TextVariant.BUTTON_MICRO: {
            return css`
                font-weight: 600;
                font-size: ${desktopTypeScale[300]}; // UX: Button is a exception from typescale rule. It has same size on mobile and desktop
                line-height: ${lineHeight[500]};

                text-transform: uppercase;
                letter-spacing: 0.02em;

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${desktopTypeScale[300]};
                    line-height: ${lineHeight[500]};
                }
            `;
        }

        case TextVariant.MINI_HEADER: {
            return css`
                font-weight: 400;
                font-size: ${desktopTypeScale[400]};
                line-height: ${lineHeight[400]};

                text-transform: uppercase;
                letter-spacing: 0.15em;

                @media screen and (min-width: ${theme.breakpoints?.md ?? "1024px"}) {
                    font-size: ${mobileTypeScale[400]};
                    line-height: ${lineHeight[400]};
                }
            `;
        }
    }
};
